import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { Form } from "src/views/use-case/components"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { compileArticleSEOFields } from "../util"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const wrapper = css`
  padding-bottom: 100px;
  padding-top: 40px;
  background-color: ${colors.background};
  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const top = css`
  margin-top: -90px;
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 50%
  );
  padding-top: 176px;
`

const inner = css`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`

const left = css`
  width: 50%;
  padding-right: 2rem;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2rem;
  }
`

const right = css`
  width: 50%;
  padding-left: 2rem;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`

const title = css`
  font-size: 42px;
  color: white;
  font-weight: bold;
  font-family: var(--heading);
  line-height: 1.24;
  padding-bottom: 3rem;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 1.5;
    padding-bottom: 2rem;
  }
  @media (max-width: 479px) {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
`

const content = css`
  font-size: 21px;
  line-height: 1.52;
  opacity: 0.75;
  color: white;
  max-width: 477px;
  @media (max-width: 479px) {
    font-size: 18px;
  }
  // Other tags to code for
  p {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.5rem;
    }
  }
  h3 {
    font-size: 1.75rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.3rem;
    }
  }
  h4 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (max-width: 479px) {
      font-size: 1.1rem;
    }
  }
  a {
    color: ${colors.orange};
  }
  li {
    margin-bottom: 1rem;
  }
  blockquote {
    margin-left: 0;
    margin-right: 1.78rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.44625rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.78rem;
    line-height: 1.78;
    color: ${colors.blockquoteText};
    font-style: italic;
    border-left: 0.33375rem solid ${colors.blockquoteBorder};
  }
`

// const banner = css`
//   margin-top: 125px;
//   @media (max-width: 479px) {
//     margin-top: 75px;
//   }
// `

const UseCaseTemplate = ({ data }) => {
  const post = data.wordpressWpUseCases
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = compileArticleSEOFields(post, siteUrl)
  return (
    <PageLayout options={{ headingBackground: false }}>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        extra={otherMetaTags}
        description={post.excerpt}
      />

      <div css={top}>
        <div css={inner}>
          <div css={left}>
            <h1 css={title}>{post.title}</h1>
          </div>
          <div css={right} />
        </div>
      </div>
      <div css={wrapper}>
        <div css={inner}>
          <div css={left}>
            <div
              dangerouslySetInnerHTML={{ __html: post.content }}
              css={content}
            />
          </div>
          <div css={right}>
            <Form />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default UseCaseTemplate

export const query = graphql`
  query UseCaseTemplate($id: String!) {
    wordpressWpUseCases(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
  }
`
