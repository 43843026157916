import React from "react"
import { css } from "@emotion/core"

import { colors, radius } from "css/theme"
import { btn } from "css/primitives"

const wrapper = css``

const wrapperInner = css`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-image: linear-gradient(
    105deg,
    ${colors.costSectionBlue} 0%,
    ${colors.costSectionOrange} 85%
  );
  border-radius: ${radius.big};
  padding: 2rem;
  overflow: hidden;
  box-shadow: 1px 2px 20px 0 #000402;
  @media (max-width: 991px) {
    max-width: 750px;
  }
`

const contentWrapper = css`
  margin-right: 2rem;
  max-width: 573px;
  @media (max-width: 991px) {
    margin-right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: none;
  }
`

const imageWrapper = css`
  flex-shrink: 0;
  margin-left: auto;
  margin-left: 85px;
  margin-right: 4rem;
  /*width: 100%;*/
  /*max-width: 528px;*/
  @media (max-width: 991px) {
    display: none;
  }
  img {
    height: 329px;
  }
`

const title = css`
  color: black;
  font-size: 32px;
  line-height: 1.38;
  font-family: var(--heading);
  font-weight: bold;
  margin-top: 1.25rem;
  margin-bottom: 20px;
  white-space: pre-line;
  @media (max-width: 991px) {
    margin-top: 0;
    white-space: normal;
  }
  @media (max-width: 767px) {
    font-size: 26px;
  }
  @media (max-width: 479px) {
    font-size: 22px;
  }
`
const description = css`
  font-size: 18px;
  line-height: 1.56;
  color: black;
  margin-bottom: 38px;
  padding-right: 1rem;
  @media (max-width: 767px) {
    opacity: 0.7;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
  @media (max-width: 479px) {
    font-size: 1rem;
  }
`
const button = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    letter-spacing: 1px;
    line-height: 2.25;
    padding: 10px 2rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 342px;
  `,
]

function CTA({ data }) {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={imageWrapper}>
          <img src={data.image.source_url} alt="" />
        </div>
        <div css={contentWrapper}>
          <div css={title}>{data.title}</div>
          <div css={description}>{data.description}</div>
          <a href={data.button_link} css={button}>
            {data.button_text}
          </a>
        </div>
      </div>
    </div>
  )
}

export default CTA
